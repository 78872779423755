@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  margin: 0;
  
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  color: #ffffff;
  text-align: left;
  background-color: #000000;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/explore_bg.png);
  animation: zoom-in-zoom-out 15s ease-out infinite;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -3;
  opacity: 0.3  ;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}


//-----------HEADER------------//
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}
.btn-main {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 48px;
  padding: 12px 32px;
  border-radius: 12px;
  position: relative;
  color: #fff;
  &::before {
    position: absolute;
    content: "";
    top: -1px;
    left: -1px;
    right: -1px;
    background: linear-gradient(
        90deg,
        #f54097 0%,
        #ef369b 7.69%,
        #e92c9f 15.38%,
        #e122a4 23.08%,
        #d918a9 30.77%,
        #d00dae 38.46%,
        #c603b3 46.15%,
        #ba00b9 53.85%,
        #ad01be 61.54%,
        #9f07c4 69.23%,
        #8e10c9 76.92%,
        #7a18cf 84.62%,
        #6220d4 92.31%,
        #3f26d9 100%
      ),
      linear-gradient(45deg, #f54097 0%, #3f26d9 100%);
    bottom: -1px;
    z-index: 1;
    -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    -khtml-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    -ms-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 12px;
  }
  &::after {
    content: "";
    background-color: transparent;
    background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%);
    border-radius: 12px;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
  }
}
span {
  position: relative;
  z-index: 2;
}

 

      //----------------BANNER-----------------//
      .fnt{
        font-family: 'Poppins', sans-serif;
      }
.banner-img{
    width: 1000px;
    height: auto;
}
.space-title{
  background-color: linear-gradient(84deg, rgb(158, 104, 221) 0%, rgb(229, 148, 229) 45%, rgb(107, 218, 176) 100%) text;;
  font-family: 'Poppins', sans-serif;
}


   //-------------------SWAP--------------------//
   .os_container {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  .os_container2 {
   
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
   
  }
  .os_container3 {
   
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-left: 30px;
   
  }
  
  .os_row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .space-between {
    justify-content: space-between;
  }
  

  header {
    padding: 25px 0px;
  
    .os_col {
      flex: 1;
      justify-content: space-between;
    }
  
    .logo {
      img {
        width: 100%;
        height: auto;
        max-width: 60px;
      }
    }
  
    .titles {
      font-size: 30px;
      text-align: center;
    }
  
    .wallet {
      text-align: right;
      
  
      .img {
        width: 40px;
        height: auto;
      filter: invert(1);
        cursor: pointer;
      }
    }
  }
  
  .mainbox {
    .title {
      text-align: center;
      margin: 0 0 15px;
      font-size: 22px;
      font-weight: 500;
      text-transform: capitalize;
      padding: 0;
      margin-bottom: 15px;
      color: #ff84d9;
    }
  
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    /* Firefox */
    input[type=number] {
      appearance: textfield;
    }
  }
  
  #root {
    min-height: 100vh;
  }
  
  .mainbox {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .css-13cymwt-control{
    background-color: transparent !important;
    color: white !important; 
  }
  .css-1nmdiq5-menu{
    background-color: black!important;
    color: white !important;
  }
  .coin-dropdown {
    background-color: transparent !important;
    color: white !important;
}
.css-1fdsijx-ValueContainer{
  padding: 0px !important;
}
.css-1dimb5e-singleValue{
  color: white !important;
}
.css-13cymwt-control{
  background: transparent !important;
}

.css-t3ipsp-control{
  background: transparent !important;
  color: white;
  

}
.css-1nmdiq5-menu:hover {
  
}

  
  .boxmain {
    border: 1px solid ;
    border-radius: 41px;
    position: relative;
    text-align: center;

    .swapbtn {
      margin: 4px auto;
      text-align: center;
      position: relative;
      z-index: 2;
      background-color: #ff84d9;
      width: 12px;
      height: 12px;
      margin-left: auto;
      margin-right: auto;
      transform: scale(4);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
  
      img {
        display: block;
        width: 100%;
        height: auto;
        max-width: 6px;
        filter: invert(1);
      }
    }
  }
  
  .approvebtn {
    padding: 0;
    width: 100%;
    height: 60px;
    
    border: 1px solid #ffffff;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    margin-top: 25px;
    color: #ffffff;
    font-size: 22px;
  
    &.mt-25 {
      margin-top: 25px;
    }
  
    img {
      height: 14px;
      width: auto;
      position: relative;
      left: 8px;
      filter: invert(1);
    }
    
  
    span {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      padding: 15px 0px;
  
      &::before,
      &::after {
        position: absolute;
        content: "";
        right: 0;
        bottom: 0;
        background: #ffffff;
        transition: all 0.3s ease;
      }
  
      &::before {
        width: 2px;
        height: 0%;
      }
  
      &::after {
        width: 0%;
        height: 2px;
      }
  
      &:hover {
        &::before {
          height: 100%;
        }
  
        &::after {
          width: 100%;
        }
      }
    }
  
    &::before,
    &::after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background: #ffffff;
      transition: all 0.3s ease;
    }
  
    &::before {
      height: 0%;
      width: 2px;
    }
  
    &::after {
      width: 0%;
      height: 2px;
    }
  
    &:hover {
      background: transparent;
      color: #ffffff;
  
      &::before {
        height: 100%;
      }
  
      &::after {
        width: 100%;
      }
    }
  }
  
  .boxinner {
    background-color: #020204;
    padding: 10px 30px 30px;
    border-radius: 7px;
    position: relative;
    text-align: left;
    border: 1px solid #5b5665;
  
    &::before {
      content: "";
      position: absolute;
      bottom: -14px;
      left: -9px;
      background-color: #020204;
      transform: rotate(-45deg);
      width: 25px;
      height: 35px;
      border-right: 1px solid #5b5665;
    }
  
    &::after {
      content: "";
      position: absolute;
      top: -14px;
      right: -9px;
      background-color: #020204;
      transform: rotate(-45deg);
      width: 25px;
      height: 35px;
      border-left: 1px solid #5b5665;
    }
  
    p {
      margin: 0px;
      text-transform: capitalize;
      color: #a5a5a5;
      font-weight: 400;
      font-size: 16px;
    }
  
    strong {
      margin: 0px;
      text-transform: capitalize;
      color: #a5a5a5;
      font-weight: 400;
      font-size: 16px;
  
      span {
        color: #ffffff;
      }
    }
  
    .boxpart {
      margin-top: 20px;
    }
  
    .leftbx {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
  
      img {
        display: block;
        width: 40px;
        margin-right: 10px;
        
        background: transparent;
        border-radius: 50px;
        padding: 3px;
        height: 30px;
       object-fit: contain;
      }
  
      strong {
        font-weight: 500;
        color: #ffffff;
      }
  
      .btnaddtkn {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 7px;
        color: #ffe6bf;
        font-size: 14px;
        text-decoration: none;
  
        &:hover {
          text-decoration: underline;
        }
      }
  
      p {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 12px;
        font-size: 14px;
  
        span {
          color: #ffffff;
        }
      }
    }
  
    input {
      flex: 1;
      margin-left: 20px;
      border: 1px solid #444444;
      padding: 10px 12px;
      border-radius: 5px;
      background-color: transparent;
      outline: none;
      box-shadow: none;
      color: #ffffff;
      text-align: right;
      width: 100%;
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
    }
  }
  
  .overlaypopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 20, 0.83);
    z-index: 3;
  }
  
  .ospopup {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: auto;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(245, 246, 252);
    box-shadow: rgba(51, 53, 72, 0.04) 8px 12px 20px, rgba(51, 53, 72, 0.02) 4px 6px 12px, rgba(51, 53, 72, 0.04) 4px 4px 8px;
    padding: 0px;
    width: 90%;
    overflow: hidden auto;
    max-width: 420px;
    max-height: 90vh;
    border-radius: 20px;
    color: #222222;
    transform: translate(-50%, -50%);
    z-index: 4;
  
    .popuptitle {
      padding: 15px 15px 20px;
  
      h3 {
        margin: 0px;
        font-size: 16px;
      }
  
      button {
        background-color: transparent;
        padding: 0;
        margin: 0;
        width: 25px;
        height: 25px;
        border-radius: 50px;
        position: relative;
        border: none;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
  
        svg {
          position: absolute;
          top: 45%;
          left: 45%;
          transform: translate(-50%, -50%);
        }
  
        &::after {
          content: "";
          position: fixed;
          top: 0;
          left: 0;
        }
      }
    }
  }
  
  .popupbtngrp {
    padding: 0px 7px 15px;
  
    button {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 15px;
      padding: 15px;
      background-color: #e8ecfb;
      border-radius: 12px;
      border: none;
      cursor: pointer;
      color: #0d111c;
      font-size: 16px;
      font-weight: 600;
      flex: 0 0 calc(50% - 14px);
      max-width: calc(50% - 14px);
      margin-left: 7px;
      margin-right: 7px;
      font-family: 'Montserrat', sans-serif;
  
      img {
        height: 28px;
        width: 28px;
        margin-right: 12px;
      }
    }
  }
  
  .osprivacy {
    padding: 10px 15px 15px;
    margin-bottom: 10px;
    color: #7780a0;
  
    a {
      text-decoration: none;
      cursor: pointer;
      color: #fb118e;
      font-weight: 500;
    }
  }
  .boxinner.bxinner.space-y-4{
    margin-top: 20px;
  }
  button.approvebtn.bxinner.flex.justify-between.items-center.mt-6.bg-blue-500.text-white.p-2.rounded{
    margin-top: 20px;
  }
  .confirmingtransaction {
    text-align: center;
  
    .loaderimg {
      width: 100%;
      max-width: 60px;
    }
  
    h2 {
      margin: 20px 0 0;
      font-size: 26px;
      font-weight: 500;
    }
  
    .approvebtn {
      max-width: calc(100% - 40px);
      margin: 30px auto 20px;
      border: none;
    }
  }
  
  .boxsmain {
    margin-top: 50px;
  
    .os_container {
      max-width: 800px;
    }
  
    .os_row {
      // justify-content: space-between;
      justify-content: center;
    }
  
    .col4 {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding: 15px;
      ;
    }
  
    .cardbox {
      background-color: #020204;
      padding: 20px 15px;
      border-radius: 7px;
      position: relative;
      text-align: center;
      border: 1px solid ;
      height: 100%;
  
      h2 {
        margin: 0 0 8px;
        font-size: 32px;
        font-weight: 600;
      }
  
      p {
        margin: 0px;
        font-size: 16px;
      }
  
      &::after {
        content: "";
        position: absolute;
        top: -14px;
        right: -9px;
        background-color: #020204;
        transform: rotate(-45deg);
        width: 25px;
        height: 35px;
        border-left: 1px solid #6c0246;
      }
  
      &::before {
        content: "";
        position: absolute;
        bottom: -14px;
        left: -9px;
        background-color: #020204;
        transform: rotate(-45deg);
        width: 25px;
        height: 35px;
        border-right: 1px solid #6c0246;
      }
    }
  
    .titleh2 {
      text-align: center;
  
      h2 {
        text-align: center;
        margin: 0 0 15px;
        font-size: 22px;
        font-weight: 500;
        text-transform: capitalize;
        padding: 0;
        margin-bottom: 15px;
        color: #ff84d9;
      }
    }
  }
  
 
  
  .content--canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: hue-rotate(1deg);
    opacity: 0.2;
  }
  
  .wrapper {
    min-height: 100vh;
  }
  
  footer {

    text-align: center;
    margin-top: 50px;
    position: sticky;
    top: 100%;
  }
  
  footer p {
    font-size: 14px;
    padding: 25px 15px;
    margin: 0px;
  }
  
  footer p a {
    color: #c40c8d;
    font-weight: 500;
    text-decoration: none;
  }
  
  .adminReportTable {
    .os_container {
      max-width: 800px;
    }
  
    .react-tabs {
      .react-tabs__tab-list {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
  
        .react-tabs__tab {
          width: 100%;
          padding: 15px;
          text-align: center;
        }
      }
    }
  
    .table-responsive {
      overflow: auto;
    
      .text-right {
        text-align: right;
      }
  
      .thead {
        .tr {
          background-color: #680d48;
  
          .th {
            padding: 15px;
          }
        }
      }
  
      .tbody {
        .tr {
          .td {
            border-bottom: 1px solid #323232;
            padding: 15px 0px;
            margin: 0;
            width: 100%;
          }
        }
      }
  
      .tr {
        .td {
          padding: 10px;
        }
      }
  
      .table {
        width: 100%;
      }
    }
  }
  /* Customize react-select styles if needed */
// .react-select-container {
//   width: 50%;
// }

// .react-select__control {
//   border: 1px solid #ccc;
//   border-radius: 4px;
// }

// .react-select__menu {
//   color: #000;
//   border-radius: 4px;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
// }

// .react-select__option--is-selected {
//   background-color: #eee;
// }


.select-dropdown {
  background-color: transparent;
}
.coin-dropdown{

color: black;
}

.coin-option {
  padding-left: 30px; /* Make room for the image */
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 5px center;
  min-height: 60px; /* Adjust if needed */
}



//-----------Analytics---------------//
.cardbox2 {

  border-radius: 8px;
  border: 1px solid ;

  h2 {
    margin: 0 0 8px;
    font-size: 32px;
    font-weight: 600;
  }

  p {
    margin: 0px;
    font-size: 16px;
  }
}


.os_row2 {
  display: flex;
  flex-wrap: wrap;
}

.boxsmain2 {
  margin-top: 50px;

  .os_container {
    max-width: 1320px;
  }

  .os_row {
    // justify-content: space-between;
    justify-content: center;
  }

  .col4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 15px;
    ;
  }
}

.cardbox2 {
  padding: 0px 0px;
  border-radius: 15px;
  position: relative;
  text-align: center;
  height: 100%;
  backdrop-filter: blur(3px);
  .vtn-card-img {
    opacity: 0.5;
    filter: hue-rotate(8deg);
  }
  /* background: transparent; */
  .iconbox {
    max-width: 100px;
    margin: 0 auto;
    position: relative;
    border-radius: 50%;
    padding: 0;
    margin-bottom: -8px;        
    img{
      width: 100%;
      height: auto;
      animation: move 2s linear infinite;
    }
  
  }

  h2 {
    margin: 0 0 8px;
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin: 0px;
    font-size: 16px;
  }


}
@keyframes move {
 
  0% {transform: translateY(0px);}
  50% {transform: translateY(-10px);}
  100% {transform: translateY(0px);}
}


//---------Table-------------//
.table-responsive {
  border: 1px solid #fff;


  .text-right {
    text-align: right;
  }

  thead {
   background-color: transparent;
    background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%); ;
    --cui-table-color-state: #fff;
    --cui-border-width: 0px;
 

    tr {
      background-color: transparent;
      background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%); ;
    }

      th {
        padding: 15px;
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid #323232;
        padding: 15px 0px;
        margin: 0;
      }
    }
  }

  tr {
    td {
      padding: 10px;
      text-align: center;
    }
  }

  table {
    width: 100%;
  }
  .header-cell {
    border-right: 1px solid #fff; /* Add a right border to the header cells */
  }

.adminReportTable {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: auto;
}
.boxsmaint {
  margin-top: 50px;

  .os_container {
    max-width: 1320px;
  }
}
.css-vubbuv {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}
.dashboardTable th, .dashboardTable td {
  border: 1px solid #fff; /* Add borders to table cells */
  padding: 8px; /* Add padding to improve cell spacing */
  text-align: left; /* Adjust text alignment if needed */
}

.historysec {
  padding-block: 50px;
  nav {
  
    justify-content: space-around;
    align-items: center;
    padding-block: 15px;
  }
  table thead tr {
    background-color: transparent;
    background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%); ;
    
      border: 1px solid #fff; /* Add borders to table cells */
      padding: 8px; /* Add padding to improve cell spacing */
      text-align: left; /* Adjust text alignment if needed */
 
    justify-content: space-between;
}
table{
  position: relative;
  border: 1px solid #791685;
  table-layout: fixed;
}

table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}
.page-link {
  
    background-color: transparent;
   
    color: #fff;

}

}


.history-headline {
  font-family: "Zen Dots", Sans-serif;
  font-size: 45px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 40px;
  span{
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.00000001);
  }
}
//-----------carousel----------//
// .carousel {
//   pointer-events: none;
// }
// .carousel-indicator-button {
//   pointer-events: all;
// }

.carousel .control-dots {
  
  
  position: absolute;
  top: 30px; /* Adjust this value as necessary */
  right: 50%;
  transform: translateX(50%); /* Centering it horizontally */
  bottom: auto;
  flex-direction: row;
  justify-content: center; /* If you want the buttons centered */
}
/* Base Styles */
.carousel-indicator-button {
  width: 100px;
  height: 40px;
  margin-right: 8px;
  padding: 8px 16px;
  border-radius: 8px; /* Full-rounded button */
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; 
  outline: none;
  cursor: pointer;
}

/* Hover State */

/* Focus State */
.carousel-indicator-button:focus {
  box-shadow: 0 0 0 4px #3f26d9; /* ring and ring-blue-500 */
}

/* Active State */
.carousel-indicator-active {
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%); /* bg-blue-500 */
  color: #F9FAFB; /* text-white */
}

/* Inactive State */
.carousel-indicator-inactive {
  // border: 1px solid white;

  background-color: transparent; /* bg-gray-200 */
  color: #fff; /* text-gray-900 */
}


//-------FOOTER--0----//
.zm_footer {
  background: #151116;
  box-shadow: inset 0 10px 20px -20px #fff;
  // padding: 20px 0;

}
.zm_footer-social {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  justify-content: center;
}
.zm_footer-copyright-text {
  p{
    margin-bottom: 0;
  }
}











  
  @media only screen and (max-width: 768px) {
    .boxsmain .col4 {
      padding: 8px;
    }
  
    .boxsmain .cardbox {
      padding: 15px 8px;
    }
  
    .boxsmain .cardbox p {
      font-size: 16px;
      min-height: 35px;
      line-height: 1.1;
    }
  }
  @media only screen and (max-width: 768px) {
    .boxsmain2 .col4 {
      padding: 8px;
    }
  
    .boxsmain2 .cardbox2 {
      padding: 0px 1px;
    }
  
    .boxsmain2 .cardbox2 p {
      font-size: 16px;
      min-height: 35px;
      line-height: 1.1;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .nav-btn-area{
      .btn-main {
        padding: 10px 10px;
      }
    }
    .os_container {
      padding-left: 15px;
      padding-right: 15px;
    }
    .os_container2 {
      padding-left: 15px;
      padding-right: 15px;
    }
    .boxsmain2 .col4 {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0px;
      margin-bottom: 20px;
    }
  
    .boxinner {
      padding: 25px 15px 30px;
    }
  
    .popupbtngrp {
      padding: 0px 15px;
  
      button {
        flex-basis: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  
    header .logo img {
      max-width: 40px;
     
      animation: move 2s linear infinite;
    }
  
    header .titles {
      font-size: 26px;
    }
  
    .boxsmain .col4 {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0px;
      margin-bottom: 20px;
    }
  
    .adminform .boxinner .inputbox button {
      margin-left: 15px;
      min-width: 85px;
      font-size: 14px;
      padding: 10px;
      text-transform: capitalize;
    }
  
    .adminform .boxinner input {
      flex-basis: calc(100% - 100px);
      max-width: calc(100% - 100px);
    }
  }

  @media(max-width:365px){
    
    .nav-btn-area{
    .btn-main {
      padding: 6px 3px;
    }
  }
  .nav-brand{

    .img{
      width: 50px;
      height: auto;
    }
  }

  }
@media(max-width:300px){
  .nav-btn-area{
  .btn-main {
    padding: 2px 1px;
  }}}