*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
.os_container_admin {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.os_row_ad {
  display: flex;
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

header .os_col {
  flex: 1;
  justify-content: space-between;
}
header .logo img {
  width: 100%;
  height: auto;
  max-width: 60px;
}
header .titles {
  font-size: 30px;
  text-align: center;
}
header .wallet {
  text-align: right;
  display: flex;
}
header .wallet button {
  margin-left: 20px;
  min-width: 100px;
  font-size: 16px;
  padding: 10px;
  border-radius: 4px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.3411764706);
  cursor: pointer;
  border: 1px solid #0c041b;
  transition: all 0.2s ease-in-out;
  position: relative;
}
header .wallet img {
  width: 40px;
  height: auto;
  filter: invert(1);
  cursor: pointer;
}

.mainbox_ad {
  /* Firefox */
}
.mainbox_ad .title {
  text-align: center;
  margin: 0 0 15px;
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 15px;
  color: #ffa902;
}
.mainbox_ad input::-webkit-outer-spin-button,
.mainbox_ad input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mainbox_ad input[type=number] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}

#root {
  min-height: 100vh;
}

.mainbox_ad {
  margin-top: 20px;
  margin-bottom: 30px;
}

.boxmain_ad {
  position: relative;
  text-align: center;
  top: 50px;
}
.boxmain_ad .swapbtn {
  margin: 4px auto;
  text-align: center;
  position: relative;
  z-index: 2;
  background-color: rgb(236, 72, 153);
  width: 12px;
  height: 12px;
  margin-left: auto;
  margin-right: auto;
  transform: scale(4);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxmain_ad .swapbtn img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 6px;
  filter: invert(1);
}

.approvebtn {
  padding: 0;
  width: 100%;
  height: 60px;
  border: 1px solid #ffffff;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  margin-top: 25px;
  color: #ffffff;
  font-size: 22px;
}
.approvebtn.mt-25 {
  margin-top: 25px;
}
.approvebtn img {
  height: 14px;
  width: auto;
  position: relative;
  left: 8px;
  filter: invert(1);
}
.approvebtn span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 0px;
}
.approvebtn span::before, .approvebtn span::after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: #ffffff;
  transition: all 0.3s ease;
}
.approvebtn span::before {
  width: 2px;
  height: 0%;
}
.approvebtn span::after {
  width: 0%;
  height: 2px;
}
.approvebtn span:hover::before {
  height: 100%;
}
.approvebtn span:hover::after {
  width: 100%;
}
.approvebtn::before, .approvebtn::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #ffffff;
  transition: all 0.3s ease;
}
.approvebtn::before {
  height: 0%;
  width: 2px;
}
.approvebtn::after {
  width: 0%;
  height: 2px;
}
.approvebtn:hover {
  background: transparent;
  color: #ffffff;
}
.approvebtn:hover::before {
  height: 100%;
}
.approvebtn:hover::after {
  width: 100%;
}

.boxinner_ad {
  padding: 10px 20px 30px;
  border-radius: 7px;
  position: relative;
  text-align: left;
  border: 1px solid #5b5665;
}
.boxinner_ad::before {
  content: "";
  position: absolute;
  bottom: -14px;
  left: -9px;
  background-color: #020204;
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-right: 1px solid #5b5665;
}
.boxinner_ad::after {
  content: "";
  position: absolute;
  top: -14px;
  right: -9px;
  background-color: #020204;
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-left: 1px solid #5b5665;
}
.boxinner_ad p {
  margin: 0px;
  text-transform: capitalize;
  color: #a5a5a5;
  font-weight: 400;
  font-size: 16px;
}
.boxinner_ad strong {
  margin: 0px;
  text-transform: capitalize;
  color: #a5a5a5;
  font-weight: 400;
  font-size: 16px;
}
.boxinner_ad strong span {
  color: #ffffff;
}
.boxinner_ad .boxpart_ad {
  margin-top: 20px;
}
.boxinner_ad .leftbx_ad {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.boxinner_ad .leftbx_ad img {
  display: block;
  width: 30px;
  margin-right: 10px;
  max-width: 30px;
  background-color: #ffffff;
  border-radius: 50px;
  padding: 3px;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.boxinner_ad .leftbx_ad strong {
  font-weight: 500;
  color: #ffffff;
}
.boxinner_ad .leftbx_ad .btnaddtkn {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 7px;
  color: #ffe6bf;
  font-size: 14px;
  text-decoration: none;
}
.boxinner_ad .leftbx_ad .btnaddtkn:hover {
  text-decoration: underline;
}
.boxinner_ad .leftbx_ad p {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 12px;
  font-size: 14px;
}
.boxinner_ad .leftbx_ad p span {
  color: #ffffff;
}
.boxinner_ad input {
  flex: 1;
  margin-left: 0px;
  border: 1px solid #444444;
  padding: 10px 12px;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  color: #ffffff;
  text-align: left;
  width: 100%;
  font-size: 16px;
}

.overlaypopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.83);
  z-index: 3;
}

.ospopup {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(245, 246, 252);
  box-shadow: rgba(51, 53, 72, 0.04) 8px 12px 20px, rgba(51, 53, 72, 0.02) 4px 6px 12px, rgba(51, 53, 72, 0.04) 4px 4px 8px;
  padding: 0px;
  width: 90%;
  overflow: hidden auto;
  max-width: 420px;
  max-height: 90vh;
  border-radius: 20px;
  color: #222222;
  transform: translate(-50%, -50%);
  z-index: 4;
}
.ospopup .popuptitle {
  padding: 15px 15px 20px;
}
.ospopup .popuptitle h3 {
  margin: 0px;
  font-size: 16px;
}
.ospopup .popuptitle button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  position: relative;
  border: none;
  cursor: pointer;
}
.ospopup .popuptitle button svg {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
}
.ospopup .popuptitle button::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
}

.popupbtngrp {
  padding: 0px 7px 15px;
}
.popupbtngrp button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #e8ecfb;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  color: #0d111c;
  font-size: 16px;
  font-weight: 600;
  flex: 0 0 calc(50% - 14px);
  max-width: calc(50% - 14px);
  margin-left: 7px;
  margin-right: 7px;
}
.popupbtngrp button img {
  height: 28px;
  width: 28px;
  margin-right: 12px;
}

.osprivacy {
  padding: 10px 15px 15px;
  margin-bottom: 10px;
  color: #7780a0;
}
.osprivacy a {
  text-decoration: none;
  cursor: pointer;
  color: #fb118e;
  font-weight: 500;
}

.confirmingtransaction {
  text-align: center;
}
.confirmingtransaction .loaderimg {
  width: 100%;
  max-width: 60px;
}
.confirmingtransaction h2 {
  margin: 20px 0 0;
  font-size: 26px;
  font-weight: 500;
}
.confirmingtransaction .approvebtn {
  max-width: calc(100% - 40px);
  margin: 30px auto 20px;
  border: none;
}

.boxsmain_ad {
  margin-top: 50px;
}
.boxsmain_ad .os_container {
  max-width: 1320px;
}
.boxsmain_ad .os_row {
  justify-content: center;
}
.boxsmain_ad .col4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 15px;
}
.boxsmain_ad .cardbox {
  padding: 20px 15px;
  border-radius: 7px;
  position: relative;
  text-align: center;
  height: 100%;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  /* background: transparent; */
}
.boxsmain_ad .cardbox .iconbox {
  max-width: 100px;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  padding: 0;
  margin-bottom: 15px;
}
.boxsmain_ad .cardbox .iconbox img {
  width: 100%;
  height: auto;
  animation: move 2s linear infinite;
}
.boxsmain_ad .cardbox h2 {
  margin: 0 0 8px;
  font-size: 20px;
  font-weight: 600;
}
.boxsmain_ad .cardbox p {
  margin: 0px;
  font-size: 16px;
}
@keyframes move {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.boxsmain_ad .titleh2 {
  text-align: center;
}
.boxsmain_ad .titleh2 h2 {
  font-family: "Poppins";
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0 0 10px;
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 15px;
}

.inputbox_ad {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0;
  margin: 0;
}
.inputbox_ad button {
  margin-left: 20px;
  min-width: 100px;
  font-size: 16px;
  padding: 10px;
  border-radius: 4px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
  background-color: rgb(153, 71, 155);
  cursor: pointer;
  border: 1px solid #0c041b;
  transition: all 0.2s ease-in-out;
  position: relative;
}
.inputbox_ad button::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: -4px;
  background-color: #000;
  transform: rotate(-45deg);
  width: 13px;
  height: 21px;
  border-right: 1px solid #0c041b;
  transition: all 0.2s ease-in-out;
}
.inputbox_ad button::after {
  content: "";
  position: absolute;
  top: -8px;
  right: -4px;
  background-color: #000;
  transform: rotate(-45deg);
  width: 13px;
  height: 21px;
  border-left: 1px solid #0c041b;
  transition: all 0.2s ease-in-out;
}
.inputbox_ad button:hover {
  border: 1px solid #c9c9c9;
}
.inputbox_ad button:hover::before, .inputbox_ad button:hover::after {
  border-color: #c9c9c9;
}

.leftbx_ad {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 5px;
}

form {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
}

input {
  text-align: left;
  margin-left: 0px;
  width: 100%;
}

.Emergencydrop {
  border: 1px solid grey;
  width: 100%;
  background: #000;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
}

.content--canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: hue-rotate(-252deg);
  opacity: 0.5;
}

.wrapper {
  min-height: 100vh;
}

footer {
  text-align: center;
  margin-top: 50px;
  position: sticky;
  top: 100%;
}

footer p a {
  color: #ffd401;
  font-weight: 500;
  text-decoration: none;
}

.adminReportTable .os_container_admin {
  max-width: 1320px;
}
.adminReportTable .react-tabs .react-tabs__tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.adminReportTable .react-tabs .react-tabs__tab-list .react-tabs__tab {
  width: 100%;
  padding: 15px;
  text-align: center;
  background: rgb(246, 178, 51);
  font-size: 20px;
  font-weight: 600;
}
.adminReportTable .table-responsive .text-right {
  text-align: right;
}
.adminReportTable .table-responsive thead tr {
  background-color: #23163a;
}
.adminReportTable .table-responsive thead tr th {
  padding: 15px;
  text-align: center;
  font-size: 15px;
  border-bottom: 1px solid #fdc06c;
}
.adminReportTable .table-responsive tbody tr td {
  border-bottom: 1px solid #323232;
  padding: 10px;
  margin: 0;
  text-align: center;
  background: black;
  color: rgba(255, 255, 255, 0.9215686275);
}
.adminReportTable .table-responsive tr td {
  padding: 10px;
}
.adminReportTable .table-responsive table {
  width: 100%;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.prevbtn {
  display: flex;
  /* display: block; */
  /* justify-content: center; */
  /* text-align: center; */
  justify-content: space-between;
  margin-top: 10px;
}
.prevbtn button {
  background: transparent;
  color: #fff;
  border: 1px solid #fdc06c;
}

.inputswapbtn {
  word-wrap: normal;
  position: absolute;
  left: 267px;
  margin-top: 12px;
  border-radius: 10%;
  border: 1px solid;
  background-color: #2b2b2d;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .boxsmain .col4 {
    padding: 8px;
  }
  .boxsmain .cardbox {
    padding: 15px 8px;
  }
  .boxsmain .cardbox p {
    font-size: 16px;
    min-height: 35px;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 575px) {
  .os_container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .boxinner_ad {
    padding: 25px 15px 60px;
  }
  .popupbtngrp {
    padding: 0px 15px;
  }
  .popupbtngrp button {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  header .logo img {
    max-width: 40px;
  }
  header .titles {
    font-size: 26px;
  }
  .boxsmain .col4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
    margin-bottom: 20px;
  }
  .adminform_ad .boxinner_ad .inputbox_ad button {
    margin-left: 15px;
    min-width: 73px;
    font-size: 14px;
    padding: 10px;
    text-transform: capitalize;
  }
  .adminform_ad .boxinner_ad input {
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }
  .inputbox_ad button {
    margin-left: 15px;
    min-width: 73px;
    font-size: 14px;
    padding: 10px;
    text-transform: capitalize;
  }
  input {
    flex-basis: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }
  .inputswapbtn {
    word-wrap: normal;
    margin-top: 0px;
    border-radius: 10%;
    background-color: #2b2b2d;
    color: #fff;
    font-size: 15px;
    margin: 10px;
    position: static;
  }
}/*# sourceMappingURL=Admin.css.map */